import api from './api';
import {
  SiteAssessment,
  SiteListMemberRole,
  SiteRoot,
} from '../../@types'

import {
  SiteListMemberRoleRequestResponse,
  SitePeopleRequestResponse,
  UserInfoRequestResponse,
} from './types';
import { objectToSnakeCase, objectToCamelCase } from "../../utils";


const parseSiteAssessmentsRequestResponseToSiteAssessments = (
  siteAssessments: any[] | undefined
): SiteAssessment[] | undefined => {
  if (!siteAssessments) {
    return undefined;
  }

  const parsed: SiteAssessment[] = [];

  siteAssessments.forEach((siteAssessment: any) => {
    parsed.push({
      clientSiteIdentifier: siteAssessment.client_site_identifier || undefined,
      publicCesiumLayerSettingsId: siteAssessment.public_cesium_layer_settings_id || undefined,
      privateCesiumLayerSettingsId: undefined,
      date: siteAssessment.date || undefined,
      storyUrl: siteAssessment.story_url || undefined,
      tilesetUrl: siteAssessment.tileset_url || undefined,
      modelGeoReferenced: siteAssessment.model_geo_referenced || false,
      modelCenterAtOrigin: siteAssessment.model_center_at_origin || false,
      id: siteAssessment.id || undefined,
      siteId: siteAssessment.site_id || undefined,
      siteListMemberId: siteAssessment.site_list_member_id || undefined,
      dataReceived: siteAssessment.data_received || undefined,
      dataType: siteAssessment.data_type || undefined,
      uploadedInternally: siteAssessment.uploaded_internally || undefined,
      uploadedToClientSite: siteAssessment.uploaded_to_client_site || undefined,
      cadprepFileUrl: siteAssessment.cad_prep_file_url || undefined,
      threeMXLink: siteAssessment.three_mx_link || undefined,
      uavoperatorId: siteAssessment.uav_operator_id || undefined,
      storage: siteAssessment.storage || undefined,
      images: undefined,
      thumbnails: undefined,
      action: siteAssessment.action || undefined,
      settings: undefined,
      storageFolder: siteAssessment.storage_folder || undefined,
      storageBucket: siteAssessment.storage_bucket || undefined,
      virtualTourUrl: siteAssessment.virtual_tour_url || undefined,
      assessmentTag: siteAssessment.assessment_tag || undefined,
      viewerUrl: siteAssessment.viewer_url || undefined,
      lastQcRecordStatus: siteAssessment.last_qc_record_status
        ? (siteAssessment.last_qc_record_status as any)
        : '',
      droneVendorCompanyId: siteAssessment.drone_vendor_company_id || undefined,
      dateCreated: siteAssessment.date_created || undefined,
      uavOperatorName: siteAssessment.uav_operator_name || undefined,
      flightAnalysisReportUrl: siteAssessment.flight_analysis_report_url || undefined,
      files: undefined,
      siteAssessmentImages: undefined,
      tenantId: siteAssessment.tenant_id || undefined,
      title: siteAssessment.title || undefined,
    });
  });

  return parsed;
};

const parseSitePeopleRequestResponseToSitePeople = (
  sitePeople: SitePeopleRequestResponse[] | undefined
): any[] | undefined => {
  if (!sitePeople) {
    return undefined;
  }

  const parsed: any[] = [];

  sitePeople.forEach((sitePerson) => {
    parsed.push({
      id: sitePerson.Id,
      siteListMemberId: sitePerson.SiteListMemberId,
      siteListMemberRoleId: sitePerson.SiteListMemberRoleId,
      userId: sitePerson.UserId,
      userUpdateId: sitePerson.UserUpdateId,
      siteListMemberRole: parseSiteListMemberRoleRequestResponseToSiteListMemberRole(
        sitePerson.SiteListMemberRole
      ),
      userInfo: parseUserInfoRequestResponseToUserInfo(sitePerson.UserInfo),
    });
  });

  return parsed;
};

const parseSiteListMemberRoleRequestResponseToSiteListMemberRole = (
  siteListMemberRole: SiteListMemberRoleRequestResponse | undefined
): SiteListMemberRole | undefined => {
  if (siteListMemberRole) {
    return {
      id: siteListMemberRole.Id,
      companyId: siteListMemberRole.CompanyId,
      name: siteListMemberRole.Name,
      userUpdateId: siteListMemberRole.UserUpdateId,
    };
  }
  return undefined;
};

const parseUserInfoRequestResponseToUserInfo = (
  userInfo: UserInfoRequestResponse | undefined
): any | undefined => {
  if (userInfo) {
    return {
      id: userInfo.Id,
      companyId: userInfo.CompanyId,
      firstName: userInfo.FirstName,
      lastName: userInfo.LastName,
      phoneNumber: userInfo.PhoneNumber,
      email: userInfo.Email,
      role: userInfo.Role,
      userId: userInfo.UserId,
      phoneVerified: userInfo.PhoneVerified,
      loginEnabled: userInfo.LoginEnabled,
      invited: userInfo.Invited,
      currentCompanyId: userInfo.CurrentCompanyId,
      fullName: userInfo.FullName,
      companyName: userInfo.CompanyName,
      company: userInfo.Company,
      dateCreated: userInfo.DateCreated,
    };
  }
  return undefined;
};

const parseSiteRequestResponseToSiteRoot = (siteRoot: any): any => ({
  id: siteRoot.id,
  companySiteId: siteRoot.name,
  siteListMemberId: siteRoot.site_list_member_id,
  companyMarket: siteRoot.company_market,
  siteId: siteRoot.id,
  region: siteRoot.region,
  tenantId: siteRoot.tenant_id,
  MKT_ID: siteRoot.mkt_id,
  AOI_Code: siteRoot.aoi_code,
  owner: {
    id: siteRoot.owner_id,
    name: siteRoot.owner_name,
    site_id: siteRoot.owner_site_id,
  },
  siteAssessments: parseSiteAssessmentsRequestResponseToSiteAssessments(siteRoot.assessments),
  sitePeople: parseSitePeopleRequestResponseToSitePeople(siteRoot.SitePeople),
  assetClassName: siteRoot.asset_class_name,
  structureTypeName: siteRoot.structure_type_name,
  structureHeightAgl: siteRoot.structure_height_agl
});

const addAssessmentIdToLayers = (target: any) => {
  Object.values(target).forEach((val: any, i: number) => {
    if (Array.isArray(val) && val.length > 0) {
      target[Object.keys(target)[i]].forEach((row: any) => {
        row.assessmentId = target.siteAssessmentId;
      });
    }
  });
};

const parseCesiumLayersResponse = (cesiumLayers: any) => {
  const layers = objectToCamelCase(cesiumLayers.cesium_layer_settings);
  layers.publicSettings && addAssessmentIdToLayers(layers.publicSettings);
  layers.privateSettings && addAssessmentIdToLayers(layers.privateSettings);

  return layers;
};

export const fetchUserProfile = async (): Promise<SiteRoot> => {
  try {
    const response = await api.fetchUserProfile();
    const userProfile = response?.user;
    if (userProfile?.current_access?.role_name) {
      userProfile.role_name = userProfile?.current_access?.role_name;
    }
    return userProfile;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchSite = async (assessmentId: string): Promise<SiteRoot> => {
  try {
    const response = await api.fetchSite(assessmentId);
    return parseSiteRequestResponseToSiteRoot(response.site);
  } catch (e) {
    console.error(e);
    throw e;
  }
};


export const fetchPhotos = async (assessmentId: string): Promise<SiteRoot> => {
  try {
    const response = await api.fetchPhotos(assessmentId);
    return response.images;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchSiteAssetGroups = async (siteListMemberId: string): Promise<any> => {
  try {
    const response = await api.fetchSiteAssetGroup(siteListMemberId);
    return response.asset_groups[0];
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchAssetGroups = async (): Promise<any> => {
  try {
    const response = await api.fetchAssetGroups();
    return { assetGroups: response.asset_groups.map((ag: any) => ({
      id: ag.id,
      name: ag.name,
    }))};
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchAssetGroupMembership = async (assetGroupId: string, siteListMemberId: string): Promise<any> => {
  try {
    const response = await api.fetchAssetGroupMembership(assetGroupId, siteListMemberId);
    return objectToCamelCase(response.membership);
  } catch (e) {
    // console.error(e);
  }
};

export const fetchAssetGroupPermission = async (assetGroupId: string): Promise<any> => {
  try {
    const response = await api.fetchAssetGroupPermission(assetGroupId);
    return objectToCamelCase(response.permission);
  } catch (e) {
    // console.error(e);
  }
};

const parseCesiumLayersRequest = (assessmentId: string, payload: any, isPublic: boolean) => {
  return { cesium_layer_settings: {
    is_public: isPublic,
    site_assessment_id: assessmentId,
    ...objectToSnakeCase(payload)
  } };
}

export const fetchCesiumLayers = async (cesiumLayersId: string): Promise<any> => {
  try {
    const response = await api.fetchCesiumLayers(cesiumLayersId);
    return parseCesiumLayersResponse(response);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const saveCesiumLayers = async (assessmentId: string, layerSettingsId: string|undefined, payload: any, isPublic: boolean): Promise<any> => {
  try {
    return layerSettingsId ?
      await api.updateCesiumLayers(layerSettingsId, parseCesiumLayersRequest(assessmentId, payload, isPublic))
      : await api.saveCesiumLayers(assessmentId, parseCesiumLayersRequest(assessmentId, payload, isPublic));
  } catch (e) {
    console.error(e);
    throw e;
  }
};