import { put, call } from 'redux-saga/effects';
import actions from '../actions';
import * as siteServices from '../../../services/site';
import { SiteAction } from '../types';
import { SiteRoot } from '../../../@types';

export function* fetchSite({ payload }: SiteAction) {
  if (!payload || !payload.assessmentId) {
    return;
  }

  const { assessmentId } = payload;

  yield put(actions.setLoading(true));

  let userProfile;
  try {
    // @ts-ignore
    userProfile = yield call(siteServices.fetchUserProfile);
    yield put(actions.setUserProfile(userProfile));
  } catch (e: any) {
    console.log('Error while fetching user roles');
  }

  try {
    const response: SiteRoot = yield call(siteServices.fetchSite, assessmentId);
  
      yield put(actions.setSite(response));
      const currentAssessment = assessmentId ? response?.siteAssessments?.find(x => x.id === assessmentId) : response?.siteAssessments?.[0];
      const currentSiteAssessmentId = assessmentId || currentAssessment?.id || undefined;

      if (currentSiteAssessmentId) {
        yield put(actions.setCurrentSiteAssessmentId(currentSiteAssessmentId));
      }

      if (userProfile.role_name === "group_dependent") {
        const assetGroupId = localStorage.getItem('consilienceanalytics.asset_group_id');
        let sitePermission;
        if (assetGroupId) {
          // @ts-ignore
          sitePermission = yield call(siteServices.fetchAssetGroupPermission, assetGroupId);
        }
        const newUserProfile = {...userProfile, role_name: sitePermission?.roleName || "viewer" };
        yield put(actions.setUserProfile(newUserProfile));
      }
  } catch (e : any) {
    yield put(actions.setError(e?.message as Error));
    alert(`Error while fetching site${e?.message && ": " + e.message}. Reload the page.`);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchPhotos({ payload }: SiteAction) {
  if (!payload || !payload.assessmentId) {
    return;
  }
  const { assessmentId } = payload;

  try {
    yield put(actions.setLoadingImages(true));
    // @ts-ignore
    const images = yield call(siteServices.fetchPhotos, assessmentId);
    const imagesWithCoord = images.filter((x:any) => x.metadata?.lat && x.metadata?.long);
    yield put(actions.setCurrentSiteImages(imagesWithCoord));
  } catch (e: any) {
    console.log(`Error while fetching images${e?.message && ": " + e.message}`);
  } finally {
    yield put(actions.setLoadingImages(false));
  }
}

export function* fetchCesiumLayers({ payload }: SiteAction) {
  if (!payload || !payload.assessmentId) {
    return;
  }

  const { assessmentId, siteListMemberId } = payload;

  yield put(actions.setLoading(true));

  try {
    // @ts-ignore
    const response = yield call(siteServices.fetchCesiumLayers, assessmentId);
    if (response.privateSettings?.id) {
      yield put(actions.setCurrentSiteAssessmentPrivateCesiumLayerSettingsId(response.privateSettings.id));
    } else {
      yield put(actions.setCurrentSiteAssessmentPrivateCesiumLayerSettingsId(undefined));
    }
    const cesiumLayers = response?.publicSettings;
    if (cesiumLayers) {
      // const memberships: any = [];
      cesiumLayers.collections = [
        ...cesiumLayers.collections.map((c: any) => {
          c.isPublic = true;
          // if (c.assetGroup && c.assetGroup !== "0" && siteListMemberId) {
          //   const permission = siteServices.fetchAssetGroupPermission(c.assetGroup);
          //   permission.then((p) => {
          //     if (p?.id) {
          //       const membership = siteServices.fetchAssetGroupMembership(c.assetGroup, siteListMemberId);
          //       membership.then((res) => memberships.push(res));
          //     }
          //   })
          // }
          return c;
        }),
        ...response.privateSettings?.collections?.map((c: any) => {
          c.isPublic = false;
          return c;
        }) || []
      ];
      cesiumLayers.measurements = [
        ...cesiumLayers.measurements.map((m: any) => {
          m.isPublic = true;
          // if (m.assetGroup && m.assetGroup !== "0" && siteListMemberId) {
          //   const permission = siteServices.fetchAssetGroupPermission(m.assetGroup);
          //   permission.then((p) => {
          //     if (p?.id) {
          //       const membership = siteServices.fetchAssetGroupMembership(m.assetGroup, siteListMemberId);
          //       membership.then((res) => memberships.push(res));
          //     }
          //   })
          // }
          return m;
        }),
        ...response.privateSettings?.measurements?.map((m: any) => {
          m.isPublic = false;
          return m;
        }) || []
      ];
      cesiumLayers.comments = [
        ...cesiumLayers.comments.map((c: any) => {
          c.isPublic = true;
          // if (c.assetGroup && c.assetGroup !== "0" && siteListMemberId) {
          //   const permission = siteServices.fetchAssetGroupPermission(c.assetGroup);
          //   permission.then((p) => {
          //     if (p?.id) {
          //       const membership = siteServices.fetchAssetGroupMembership(c.assetGroup, siteListMemberId);
          //       membership.then((res) => memberships.push(res));
          //     }
          //   })
          // }
          return c;
        }),
        ...response.privateSettings?.comments?.map((c: any) => {
          c.isPublic = false;
          return c;
        }) || []
      ];
      cesiumLayers.primitives = [
        ...cesiumLayers.primitives.map((c: any) => {
          c.isPublic = true;
          return c;
        }),
        ...response.privateSettings?.primitives?.map((c: any) => {
          c.isPublic = false;
          return c;
        }) || []
      ];
      yield put(actions.setCurrentSiteAssessmentPublicCesiumLayerSettingsId(response.publicSettings.id));
      yield put(actions.setCesiumLayers(cesiumLayers));

      // @ts-ignore
      // const assetGroups = yield call(siteServices.fetchAssetGroups);
      // assetGroups.memberships = memberships;
      // yield put(actions.setAssetGroups(assetGroups));
    } else {
      yield put(actions.setCesiumLayers({}));
    }
  } catch (e : any) {
    yield put(actions.setError(e?.message as Error));
    alert(`Error while fetching site${e?.message && ": " + e.message}. Reload the page.`);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* saveCesiumLayers({ payload }: SiteAction) {
  if (!payload || !payload.assessmentId) {
    return;
  }

  const { assessmentId, publicCesiumLayerSettingsId, privateCesiumLayerSettingsId, cesium, savePublicAllowed } = payload;
  yield put(actions.setLoading(true));

  try {
    // @ts-ignore
    const publicCesium = structuredClone(cesium);
    // @ts-ignore
    const privateCesium = structuredClone(cesium);

    privateCesium.measurements = privateCesium.measurements.filter((x: any) => !x.isPublic);
    privateCesium.comments = privateCesium.comments.filter((x: any) => !x.isPublic);
    privateCesium.collections = privateCesium.collections.filter((x: any) => !x.isPublic);
    privateCesium.primitives = privateCesium.primitives.filter((x: any) => !x.isPublic);
    // @ts-ignore
    const privateResponse = yield call(siteServices.saveCesiumLayers, assessmentId, privateCesiumLayerSettingsId, privateCesium, false);
    yield put(actions.setCurrentSiteAssessmentPrivateCesiumLayerSettingsId(privateResponse.cesium_layer_settings.id));

    if (savePublicAllowed) {
      publicCesium.measurements = publicCesium.measurements.filter((x: any) => x.isPublic);
      publicCesium.comments = publicCesium.comments.filter((x: any) => x.isPublic);
      publicCesium.collections = publicCesium.collections.filter((x: any) => x.isPublic);
      publicCesium.primitives = publicCesium.primitives.filter((x: any) => x.isPublic);
      // @ts-ignore
      const publicResponse = yield call(siteServices.saveCesiumLayers, assessmentId, publicCesiumLayerSettingsId, publicCesium, true);
      yield put(actions.setCurrentSiteAssessmentPublicCesiumLayerSettingsId(publicResponse.cesium_layer_settings.id));
    }
  } catch (e : any) {
    yield put(actions.setError(e?.message as Error));
    alert(`Error while saving layers${e?.message && ": " + e.message}.`);
  } finally {
    yield put(actions.setLoading(false));
  }
}